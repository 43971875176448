import React from 'react';
import fb from "./../../assets/icons/fb.svg";
import yt from "./../../assets/icons/yt.svg";

export default class Fanpage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      categories: [],
      brands: {},
    };
  }

    render() {
        return (
            <div className="contact-info">
                <p className="mb-4"><strong>KẾT NỐI VỚI CHÚNG TÔI</strong></p>
                <p>
                    <a   href={this?.props?.dataSetup?.social_facebook} className="icon" target="_blank" title="Facebook">
                        <img src={fb} width="32" alt="" />
                    </a>
                    <a   href={this?.props?.dataSetup?.social_youtube} className="icon" target="_blank" title="Youtube">
                        <img src={yt} width="32" alt="" />
                    </a>
                </p>
            </div>
        );
    }
}
