import React, { useEffect, useCallback, useState } from "react";
import logo from "./../../assets/2cnet/logo.png";
import "./App.scss";
import Header from "./../../components/headers/v1/header";
import Footer from "./../../components/footers/three-columns";
import Info from "./../../components/contacts/info";
import Fanpage from "./../../components/contacts/fanpage";
import CustomerSupport from "./../../components/contacts/customer-support";
import Copyright from "./../../components/footers/copyright";
import { routes } from "../../routes";
import HelmetData from "../../components/metas/helmet";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import vi from "date-fns/locale/vi";

import { currentUser } from "../../actions/AuthAction";
import { useAuthContext } from "../../contexts/AuthContext";
import BrandsService from "../../services/brands";

export default function App() {
    registerLocale("vi", vi);
    const { dispatch } = useAuthContext();
 const [dataSetup,setDataSetup]= useState<any>()
    const header = {
        hotline: "CSKH : " + process.env.REACT_APP_HOTLINE,
        logo: logo,
    };

    const checkCurrentCustomer = useCallback(async () => {
        await currentUser(dispatch);
    }, [dispatch]);

    useEffect(() => {
        checkCurrentCustomer();
    }, [checkCurrentCustomer]);

     useEffect(() => {
        document.title =  dataSetup?.brand_code || "AZpro";
    }, []);
    useEffect(()=>{
        const fetchDataSetup = async () => {
            const response = await new BrandsService().getBrands();
            setDataSetup(response?.items[0])
        }
        fetchDataSetup()
    },[])

    const renderRoutes = useCallback((routes) => {
        let results = null;
        if (routes.length > 0) {
            results = routes.map((route: any, index: any) => {
                const { path, exact, component, type: RouteType } = route;
                return (
                    <RouteType
                        key={index}
                        path={path}
                        exact={exact}
                        component={component}
                    />
                );
            });
        }
        return <Switch>{results}</Switch>;
    }, []);

    return (
        <Router>
            <HelmetData />
            <Header {...header} />
            <div className="content-wrapper">
                <p
                    style={{
                        fontSize: "14px",
                        width: "100%",
                        textAlign: "center",
                        color: "#ff9c00",
                        display: "block",
                    }}
                >
                   {dataSetup?.banner_sentence}
                </p>
                {renderRoutes(routes)}
            </div>
            <div className="footer">
           <Footer
                dataSetup={dataSetup}
                    first={<Info  dataSetup={dataSetup}/>}
                    third={<Fanpage dataSetup={dataSetup}/>}
                    second={<CustomerSupport />}
                />
                <Copyright dataSetup={dataSetup} />
            </div>
        </Router>
    );
}

